<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import ApiClient from '@api'
    export default {
        mixins: [mixins.RaDialogMixin],

        props: {
            element: {
                default: null,
            },
            route: {
                type: String,
                default: '',
            },
            property: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: 'NO PLACEHOLDER DEFINED',
            },
            message: {
                type: String,
                default: 'NO MESSAGE PROP DEFINED',
            },
            title: {
                type: String,
                default: 'NO TITLE DEFINED',
            },
            doubleVerification: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                confirmed: '',
                isValid: false,
                loading: false,
            }
        },
        computed: {
            _value() {
                if (!this.element) return ''
                let el = this.element[this?.property]
                let type = typeof el
                if (type != 'string')
                    throw new Error(
                        'The property to confirmed must be a string'
                    )
                return this.stringUniform(el)
            },
            hasOnDeleteEventListener() {
                return this.$attrs.hasOwnProperty('onDelete')
            },
        },
        methods: {
            stringUniform(value) {
                return value.toLowerCase().replace(' ', '-')
            },

            deleteElement() {
                if (this.hasOnDeleteEventListener) {
                    this.$emit('delete', this.element)
                    this.close()
                } else {
                    if (!Boolean(this.route))
                        throw new Error('Route delete must be defined')
                    if (!this?.element?.id)
                        throw new Error(
                            "The element given must have a property number named 'id'"
                        )

                    this.loading = true
                    let api = new ApiClient()
                    api.delete(this.route + this?.element?.id)
                        .call()
                        .then(() => {
                            this.$emit('close')
                            this.close()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            },
        },
        watch: {
            confirmed() {
                this.isValid = this._value == this.confirmed
            },
            isOpen() {
                this.confirmed = ''
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>
            <span v-html="title"></span>
        </template>
        <ra-box padding="4,">
            <p v-html="message"></p>
            <input
                :value="_value"
                class="confirm"
                disabled
                maxlength="255"
                minlength="3"
                required
                style="text-align: center; width: 100%"
                type="text"
            />
            <p
                v-if="doubleVerification"
                v-html="$t('generics.delete_confirm')"
            ></p>
            <ra-input>
                <v-text-field
                    v-if="doubleVerification"
                    v-model="confirmed"
                    :placeholder="placeholder"
                    hide-details
                    type="text"
                    variant="outlined"
                ></v-text-field>
            </ra-input>
            <v-row class="mt-7">
                <v-col sm="auto">
                    <v-btn
                        class="light"
                        color="primary"
                        variant="plain"
                        @click="$emit('close')"
                    >
                        {{ $t('generics.cancel') }}
                    </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col sm="auto">
                    <v-btn
                        :disabled="doubleVerification && !isValid"
                        :loading="loading"
                        class="button block bg-primary-gradient"
                        type="submit"
                        @click="deleteElement"
                    >
                        {{ $tc('generics.delete') }}
                    </v-btn>
                </v-col>
            </v-row>
        </ra-box>
    </ra-base-dialog>
</template>
<style lang="scss" scoped>
    .confirm {
        padding: 10.5px 15px;
        margin: 1em 0;
        color: var(--primary);
        border-radius: 7px;
        border: 1.5px solid #dfdbed;
        font-weight: 700;

        outline: none;
        margin-bottom: 10px;
        background-color: rgba(223, 219, 237, 0.5);
    }
</style>

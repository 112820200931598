export function typesAvailable() {
    const prefix = 'formers.properties.role.role_values'
    return [
        {
            value: 1,
            name: this.$t(`${prefix}.former`),
        },
        {
            value: 3,
            name: this.$t(`${prefix}.presta`),
        },
        {
            value: 4,
            name: this.$t(`${prefix}.god`),
        },
        {
            value: 5,
            name: this.$t(`${prefix}.super_god`),
        },
    ]
}

function platformsInputs(former, platforms, errors = null, edit) {
    return former?.company?.is_demo
        ? [
              {
                  type: 'form-row',
                  elements: [
                      {
                          is: 'form-input',
                          type: 'select',
                          options: platforms.map((p) => ({
                              name: p.name,
                              value: p.id,
                          })),
                          required: true,
                          placeholder: this.$t(
                              'companies.properties.platform.placeholder'
                          ),
                          name: 'user.demo_platform_id',
                          errors,
                          label: this.$t('companies.properties.title_platform'),
                          evaluated: {
                              get() {
                                  return former.demo_platform_id
                              },
                              set(val) {
                                  former.demo_platform_id = val
                              },
                          },
                      },
                  ],
              },
          ]
        : []
}

export function formerAccountType(user, company) {
    const all_types = typesAvailable.call(this)
    let types = []
    if (user.type == 3) return []
    if (user.type >= 4 && company.id == user.company_id) {
        for (let i = 0; i < all_types.length; i++) {
            if (all_types[i].value <= user.type) {
                types.push(all_types[i])
            }
        }
    } else {
        types.push(all_types[0])
    }

    return types
}
function getTypeInputs(user, company) {
    return user.hasRole(['god', 'super_god'])
        ? [
              {
                  type: 'form-row',
                  elements: [
                      {
                          is: 'form-input',
                          type: 'select',
                          property: 'type',
                          label: this.$t('formers.properties.role.label'),
                          options: formerAccountType.call(this, user, company),
                      },
                  ],
              },
          ]
        : [{}]
}
export function formerFormInputs(company, edit) {
    const user = this.user
    const typeInputs = getTypeInputs.call(this, user, company)
    const checkBoxInputs = []

    if (company.is_vr)
        checkBoxInputs.push({
            is: 'form-input',
            label: this.$t(
                edit
                    ? 'formers.properties.is_vr.label'
                    : 'formers.properties.is_vr.label_create'
            ),
            type: 'checkbox',
            property: 'is_vr',
        })

    if (this.company.is_elearning && !this.company.is_demo)
        checkBoxInputs.push({
            is: 'form-input',
            label: this.$t(
                edit
                    ? 'formers.properties.is_elearning.label'
                    : 'formers.properties.is_elearning.label_create'
            ),
            property: 'is_elearning',
            type: 'checkbox',
        })

    return [
        {
            type: 'form-title',
            content: 'Informations',
            visible: !edit,
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'lastname',
                    label: this.$t('users.properties.lastname.label'),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'firstname',
                    label: this.$t('users.properties.firstname.label'),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'email',
                    label: this.$t('users.properties.email.label'),
                    required: true,
                },

                {
                    is: 'form-input',
                    type: 'text',
                    property: 'phone',
                    label: this.$t('users.properties.tel.label'),
                },
            ],
        },
        ...typeInputs,
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'group-selector',
                    property: 'group_id',
                    label: this.$tc('groups._name', 1),
                    inputProps: {
                        company_id: company.id,
                        category: 3,
                        user_id: user.id,
                    },
                },
            ],
        },

        {
            type: 'form-row',
            elements: checkBoxInputs,
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    label: this.$t('formers.properties.is_admin.label'),
                    property: 'admin',
                    type: 'checkbox',
                    inputProps: {
                        hint: this.$t('formers.properties.is_admin.label.hint'),
                        persistentHint: true,
                    },
                },
                {
                    is: 'form-input',
                    label: this.$t('formers.properties.notify.label'),
                    property: 'notify',
                    type: 'checkbox',
                    inputProps: {
                        persistentHint: true,
                    },
                },
            ],
        },
    ]
}

export function formerViewFormInputs(former, selfForm = false, platforms = []) {
    const user = this.user
    const typeInputs = selfForm
        ? []
        : getTypeInputs.call(this, user, former.company)
    let trad_prefix = selfForm ? 'your_' : ''
    return [
        ...typeInputs,
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'lastname',
                    label: this.$t(
                        'users.properties.' + trad_prefix + 'lastname.label'
                    ),
                    required: true,
                },
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'firstname',
                    label: this.$t(
                        'users.properties.' + trad_prefix + 'firstname.label'
                    ),
                    required: true,
                },
            ],
        },
        {
            type: 'form-row',
            elements: [
                {
                    is: 'form-input',
                    type: 'text',
                    property: 'email',
                    label: this.$t(
                        'users.properties.' + trad_prefix + 'email.label'
                    ),
                    required: true,
                },

                {
                    is: 'form-input',
                    type: 'text',
                    property: 'phone',
                    label: this.$t(
                        'users.properties.' + trad_prefix + 'tel.label'
                    ),
                },
            ],
        },
        ...(user.isGod
            ? platformsInputs.call(this, former, platforms, true, false)
            : []),
        {
            type: 'form-row',
            visible: user.isGod,
            elements: [
                {
                    is: 'form-input',
                    type: 'checkbox',
                    property: 'is_vr',
                    label: this.$t('formers.properties.is_vr.label'),
                    inputProps: {
                        disabled: !former.is_elearning,
                    },
                },
                {
                    is: 'form-input',
                    type: 'checkbox',
                    visible: !former?.company?.is_demo,
                    property: 'is_elearning',
                    label: this.$t('formers.properties.is_elearning.label'),

                    inputProps: {
                        disabled: !former.is_vr,
                    },
                },
            ],
        },
        {
            type: 'form-row',
            visible: user.isGod,
            elements: [
                {
                    is: 'form-input',
                    type: 'checkbox',
                    property: 'admin',
                    label: this.$t('formers.properties.is_admin.label'),
                    inputProps: {
                        // hint: this.$t("formers.properties.is_admin.label.hint"),
                    },
                },
            ],
        },
    ]
}

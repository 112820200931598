<script>
    import { TranslationAlias } from '@core/i18n/TranslationAlias.js'
    import { formerFormInputs } from '@components/Formers/FormerForm.inputs.js'
    import { FormersProvider } from '@providers'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import withUser from '@mixins/withUser.js'

    export default {
        name: 'FormerAddForm',
        mixins: [mixins.RaDialogMixin, withUser],
        data() {
            return {
                translationAlias: new TranslationAlias('formers', this),
                former: null,
                error: null,
            }
        },
        props: {
            company: {
                type: Object,
                required: true,
            },
        },
        computed: {
            inputs() {
                return formerFormInputs.call(this, this.company, false)
            },
        },
        created() {
            this.reset()
        },
        methods: {
            reset() {
                this.former = {
                    type: 1,
                    is_test_user: false,
                    is_demo: false,
                    group_id: null,
                    admin: false,
                    is_vr: true,
                    is_elearning: true,
                    firstname: '',
                    lastname: '',
                    email: '',
                }
            },
            async handleSubmit() {
                const { data } = await FormersProvider.createFormer(
                    this.company.id,
                    this.former
                )
                    .bindVue(this)
                    .call()
                this.$emit('added')
                this.close()
            },
        },
    }
</script>

<template>
    <ra-base-dialog>
        <template #title>{{ $_t('actions.add') }}</template>
        <ra-form
            :element="former"
            :error="error"
            :inputs="inputs"
            @submit="handleSubmit"
        ></ra-form>
    </ra-base-dialog>
</template>

<script>
export default {
  name: "PackChip",
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    pack: {
      type: Object,
      default: () => {},
    },
    inline: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "12px",
    },
    variant: {
      type: String,
      default: "gradient",
    },
  },
  computed: {
    gradient() {
      if (this.variant != "gradient") return null;
      return `linear-gradient(90deg, ${this.pack?.left_color} 0%, ${this.pack?.right_color} 100%)`;
    },
    textGradient() {
      return `linear-gradient(90deg, ${this.pack?.left_color} 0%, ${this.pack?.right_color} 100%)`;
    },
  },
};
</script>

<template>
  <ra-box
    v-if="pack"
    :class="[inline && 'pack-chip-inline', !active && 'pack-chip-disabled']"
    :height="height"
    :style="{
      '--left-color': pack?.left_color,
      '--right-color': pack?.right_color,
      backgroundColor: '#ececf5',
      backgroundImage: gradient,
    }"
    centered-content
    class="pack-chip"
    padding="2"
    v-bind="$attrs"
  >
    <template v-if="variant == 'gradient'">
      <img v-if="pack?.title_image?.url" :src="pack?.title_image?.url" />
      <ra-typo v-else bold style="text-transform: uppercase">
        {{ pack?.name }}
      </ra-typo>
    </template>
    <ra-typo
      v-else
      :text="{
        style: {
          backgroundImage: textGradient,
        },
      }"
      bold
    >
      {{ pack.name }}
    </ra-typo>
  </ra-box>
</template>

<style lang="scss">
.pack-chip-disabled {
  background-image: none !important;

  .ra-typography {
    //remove gradient
    background-image: none !important;
    background-color: #ececf5 !important;
    -webkit-text-fill-color: unset !important;
    -webkit-background-clip: unset !important;
    -moz-background-clip: unset !important;
    -moz-text-fill-color: unset !important;
    color: var(--primary-lighten) !important;
  }
}

.pack-chip {
  &.rounded {
    border-radius: 4px !important;
  }

  box-sizing: content-box;

  img {
    display: block;
    height: 100%;
    width: auto !important;
    object-fit: unset !important;
  }

  .ra-typography {
    background-color: #ececf5;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
}

.pack-chip-inline {
  display: inline-block;
}
</style>

import { defineStore } from 'pinia'
import FormationCollection from '@libs/Collections/Formation.js'
import { GLOBAL_ENV } from '@store/globalEnv.js'

export const useFormationStore = () => {
    const store = defineStore('formationStore', {
        state: () => ({
            formations: new FormationCollection(),
            _formations: new FormationCollection(),
        }),

        getters: {
            formationsVr(state) {
                return state.formations.vr
            },
            formationsElearning(state) {
                return state.formations.elearning
            },
            formationsEnv(state) {
                const env = localStorage.getItem('globalEnv')
                if (env == GLOBAL_ENV.ELEARNING) {
                    return state.formationsElearning
                } else {
                    return state.formationsVr
                }
            },
        },
        actions: {
            async getFormations(env = null) {
                await this.fetchFormations()
                let formations = this.formations.clone()
                if (env) {
                    return formations.filter(
                        (formation) => formation.type === env
                    )
                }
                return formations
            },
            async fetchFormations(query = {}, cache = true) {
                if (this._formations.loading)
                    return new Promise((resolve) => {
                        this._formations.on('fetched', () => resolve())
                    })
                if (this._formations.lastFetchedAt && cache) return this.reset()

                await this._formations.fetch(query, 'data.data')
                this.reset()
            },
            reset() {
                this.formations.set(this._formations.toArray())
            },
        },
    })
    const instance = store()
    instance.fetchFormations({
        withPacks: true,
        getResource: true,
        ignoreEnv: true,
    })
    return instance
}

export class WSHeartBeat {
    static pongMessage = JSON.stringify({
        type: 'pong',
    })
    static pingMessage = JSON.stringify({
        type: 'ping',
    })
    static isPingMessage(message) {
        return message === WSHeartBeat.pingMessage
    }
    static sendPong(socket) {
        socket.send(WSHeartBeat.pongMessage)
    }
}

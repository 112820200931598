<script>
import PacksFilter from "@components/Packs/PacksFilter.vue";
import FormationGrid from "@components/Formations/FormationGrid.vue";
import ModuleGrid from "@components/FormationsModules/ModuleGrid.vue";
import StartFormationDescription from "@components/Headsets/StartFormation/StartFormationDescription.vue";
import { mixins } from "@realityteam/reality-academy-ui-kit";

export default {
  name: "SelectModule",
  mixins: [mixins.RaTabsMixin],
  props: {
    formation: {
      type: Object,
      default: () => {},
    },
    modulesSelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentTab: 0,
      selectionMode: false,
    };
  },
  components: {
    StartFormationDescription,
    ModuleGrid,
    FormationGrid,
    PacksFilter,
  },
  computed: {
    value: {
      get() {
        return this.modulesSelected;
      },
      set(value) {
        this.$emit("update:modulesSelected", value);
      },
    },
    hasModule(){
      return this.formation.modules.some(m => m.is_visible === undefined ? true : m.is_visible)
    }
  },
  emits: ["update:modulesSelected","start-formation"],
  methods: {
    toggleModule(module) {
      if (this.selectionMode == false) return;
      if (this.isSelected(module)) {
        this.value = this.value.filter((m) => m.id !== module.id);
      } else {
        this.value.push(module);
      }
    },
    isSelected(module) {
      return this.value.includes(module);
    },
    startFormationFromModule(module){
      const index = this.formation.modules.findIndex(m=>m.id === module.id)
      this.value = this.formation.modules.slice(index)
      this.$emit('start-formation', this.value)
    }
  },
  watch: {
    selectionMode(value) {
      if (!value) {
        this.value = [];
      }
    },
  },
};
</script>

<template>
  <ra-box center>
    <ra-typo bold uppercase variant="title">
      {{ formation.name }}
    </ra-typo>
  </ra-box>
  <ra-box>
    <ra-stack alignement="center" direction="horizontal">
      <ra-box style="flex: 1 1 100%">
        <v-tabs
          v-model="currentTab"
          color="#090954"
          density="compact"
          flat
          slider-size="3"
        >
          <v-tab @click="hideHandShowTabs" v-if="hasModule">
            <ra-typo semi>
              {{ $tc("formations.modules._name", 1) }}
            </ra-typo>
          </v-tab>
          <v-tab @click="hideHandShowTabs">
            <ra-typo semi>
              {{ $tc("headsets.pilotage.steps.step1.tabs.description", 1) }}
            </ra-typo>
          </v-tab>
        </v-tabs>
      </ra-box>
      <ra-box style="flex: 0 0 auto" v-if="hasModule && currentTab == 0">
        <ra-btn
          density="compact"
          variant="outlined"
          @click="selectionMode = !selectionMode"
        >
          <ra-typo semi uppercase>
            {{
              selectionMode
                ? $t("generics.cancel")
                : $t("headsets.pilotage.steps.step1.select_module")
            }}
          </ra-typo>
        </ra-btn>
      </ra-box>
    </ra-stack>
  </ra-box>
  <ra-box>
    <v-fade-transition>
      <module-grid
        v-if="currentTab == 0 && tabsDisplay && hasModule"
        :modules="formation.modules"
      >
        <ra-stack
          v-for="module of formation.modules"
          :class="[isSelected(module) && 'selected']"
          alignement="scretch"
          bg="surface-darken-1"
          class="select-module-card"
          direction="horizontal"
          distribution="fill"
          gap="7"
          rounded
          @click="toggleModule(module)"
        >
          <img
            :src="module?.image?.url"
            alt="module_image"
            style="display: block"
          />
          <ra-stack
            class="text-module"
            distribution="side-spacing"
            gap="0"
            padding="2,"
          >
            <ra-typo bold uppercase variant="subtitle">
              {{
                module.is_intro
                  ? $t("formations.modules._intro")
                  : $tc("formations.modules._name", 1) + " " + module.number
              }}
            </ra-typo>
            <ra-typo variant="x-small">{{ module.name }}</ra-typo>
          </ra-stack>

          <ra-box centered-content padding="pr-8">
            <div
              v-if="selectionMode"
              class="ra-radio"
              @click.stop="toggleModule(module)"
            ></div>
            <v-btn icon v-else variant="plain" density="comfortable" @click="startFormationFromModule(module)">
              <v-icon  color="primary-lighten-1">mdi-play</v-icon>
            </v-btn>
          </ra-box>
        </ra-stack>
      </module-grid>
      <start-formation-description
        v-else-if="tabsDisplay"
        :formation="formation"
      />
    </v-fade-transition>
  </ra-box>
</template>

<style lang="scss" scoped>
.module-grid {
  .select-module-card {
    cursor: pointer;

    &.selected {
      background: #d4d4f2 !important;

      .ra-radio {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
          content: "";
          position: absolute;
          width: calc(var(--size) - 16px);
          height: calc(var(--size) - 16px);
          background: var(--primary);
          border-radius: calc(var(--size) - 16px);
        }
      }
    }

    img {
      width: 100px;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 97/65;
    }

    .text-module {
      flex: 1 1 70% !important;
    }

    > .ra-box {
      flex: 1 0 auto;
      width: auto;
      justify-content: flex-end;
    }
  }

  .ra-radio {
    --size: 28px;
    width: var(--size);
    height: var(--size);
    background: white;
    border-radius: var(--size);
    cursor: pointer;
  }
}
</style>

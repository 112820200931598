<template>
    <div>
        <ra-alert
            ref="alert"
            global
        />
        <web>
            <pilotage-settings
                v-if="settings"
                v-model:settings="settings"
                :headset="headset"
            />
        </web>
        <tablet>
            <ra-box
                v-if="settings"
                class="headset-settings"
                padding="4"
            >
                <ra-stack
                    :gap="headset.isConnected ? null : 6"
                    :style="
                        headset.isConnected
                            ? {
                                  justifyContent: 'space-between',
                                  minHeight: 'calc(100vh - 100px)',
                              }
                            : null
                    "
                    bg="white"
                    padding="4,8"
                    rounded
                >
                    <ra-box center>
                        <ra-typo
                            bold
                            uppercase
                            variant="title"
                        >
                            {{ $t('headsets.settings.title_card') }}
                        </ra-typo>
                        <v-btn
                            class="headset-settings-close"
                            density="comfortable"
                            icon="mdi-arrow-left"
                            variant="plain"
                            @click="$emit('close')"
                        />
                    </ra-box>

                    <ra-stack gap="4">
                        <ra-stack
                            alignement="strech"
                            direction="horizontal"
                            distribution="fill-equaly"
                            gap="4"
                            mobile-swap
                            wrap
                        >
                            <ra-stack
                                bg="surface-darken-1"
                                distribution="fill"
                                padding="4"
                                rounded
                            >
                                <ra-typo
                                    bold
                                    variant="subtitle"
                                >
                                    {{
                                        $t(
                                            'headsets.settings.headset_name_section'
                                        )
                                    }}
                                </ra-typo>
                                <ra-stack
                                    alignement="center"
                                    direction="horizontal"
                                    distribution="fill"
                                    gap="4"
                                >
                                    <img
                                        :src="headset.image"
                                        alt=""
                                        class="object-fit-contain"
                                        height="54"
                                    />
                                    <ra-input
                                        name="pretty_name"
                                        :errors="errors"
                                    >
                                        <v-text-field
                                            v-model="temporary_name"
                                            :placeholder="
                                                $t(
                                                    'headsets.settings.headset_name_section'
                                                )
                                            "
                                            density="comfortable"
                                            variant="outlined"
                                        ></v-text-field>
                                    </ra-input>
                                </ra-stack>
                            </ra-stack>
                            <headset-release-installed-card
                                v-if="headset?.state?.releaseInstalled"
                                :releaseInstalled="
                                    headset?.state?.releaseInstalled
                                "
                            />
                        </ra-stack>
                        <pilotage-settings
                            v-if="headset.isConnected"
                            v-model:settings="settings"
                            :headset="headset"
                        />
                    </ra-stack>

                    <ra-stack
                        class="btn-stack"
                        direction="horizontal"
                        distribution="side-spacing"
                        gap="6"
                        mobile-swap
                        wrap
                    >
                        <ra-btn
                            bg="primary-lighten-1"
                            semi
                            uppercase
                            width="230px"
                            @click="handleDelete()"
                        >
                            {{ $t('headsets.actions.delete._name') }}
                        </ra-btn>
                        <ra-btn
                            bg="primary-gradient"
                            semi
                            uppercase
                            width="230px"
                            @click="handleSave"
                            :disabled="temporary_name.length < 3"
                        >
                            {{ $t('generics.save') }}
                        </ra-btn>
                    </ra-stack>
                </ra-stack>
            </ra-box>
        </tablet>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia'
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import { HeadsetProvider } from '@providers/HeadsetProvider'

    import ConnectedStatus from './ConnectedStatus.vue'
    import BatteryLevel from './BatteryLevel.vue'

    import SearchHeadsetDialog from './SearchHeadsetDialog.vue'
    import HeadsetsList from './HeadsetsList.vue'
    import { useAppStore } from '@store/app'
    import PilotageSettings from '@components/Headsets/PilotageSettings.vue'
    import HeadsetReleaseInstalledCard from '@components/Headsets/Details/Cards/HeadsetReleaseInstalledCard.vue'
    import { useMDMStore } from '@store/mdm.js'
    import { useGlobalDialog } from '@store/globalDialog.js'
    import withUser from '@mixins/withUser.js'
    import { useGlobalSnackBar } from '@store/globalSnakbar.js'

    export default {
        components: {
            HeadsetReleaseInstalledCard,
            PilotageSettings,
            ConnectedStatus,
            BatteryLevel,
            SearchHeadsetDialog,
            HeadsetsList,
        },

        mixins: [mixins.RaAlertMixin, withUser],
        name: 'HeadsetSettings',
        emits: ['close'],

        data() {
            return {
                temporary_name: '',
                errors: null,
            }
        },

        mounted() {
            if (this.headset) {
                this.headset.setStateProtection(true)

                this.temporary_name = this.headset.name
                this.headset.on('state', this.handleNewState.bind(this))
            }
            this.handleEmptyState()
        },
        unmounted() {
            if (this.headset) {
                this.headset.setStateProtection(false)
                this.headset.off('state', this.handleNewState.bind(this))
            }
        },

        computed: {
            settings: {
                get() {
                    return this.headset.state.settings || null
                },
                set(value) {
                    this.headset.state.settings = value
                },
            },

            headset: {
                get() {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                },
            },
            isSameUser() {
                return this.headset.isSameUser(this.user)
            },
            ...mapState(useAppStore, ['isOffline']),
        },

        props: {
            modelValue: {
                type: Object,
                default: null,
            },
        },

        methods: {
            ...mapActions(useMDMStore, ['unSyncAnHeadset']),
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            ...mapActions(useGlobalSnackBar, ['showSnackBar', 'hideSnackBar']),
            handleNewState(state) {
                let oldState = this.headset.state
                if (
                    JSON.stringify(oldState.settings) !==
                    JSON.stringify(state.settings)
                ) {
                    this.showSnackBar('headsets.alerts.settings_changed', {
                        closable: false,
                        class: ['default-alert', 'left-alert', 'text-left'],
                        actions: [
                            {
                                text: this.$t('generics.refresh'),
                                handler: () => {
                                    this.hideSnackBar()
                                    this.headset.setStateProtection(false)
                                    this.headset.setState(state, true)
                                    this.headset.setStateProtection(true)
                                },
                            },
                            {
                                text: this.$t('generics.ignore'),
                                handler: () => {
                                    this.hideSnackBar()
                                },
                            },
                        ],
                    })
                }
            },
            handleEmptyState() {
                const defaultSettings = {
                    interactionMode: 'pointer',
                    controllerPreferences: 'right',
                    language: 'fr',
                    launchTraining: 'use',
                    learnerCanChooseModule: true,
                    quiz: {
                        vr: true,
                        el: true,
                    },
                }
                if (!this.settings) {
                    this.headset.state = {
                        ...this.headset.state,
                        settings: defaultSettings,
                    }
                }
            },
            async handleSave() {
                this.headset.name = this.temporary_name
                await this.headset.setLocalRenaming(this.temporary_name)
                if (!this.isOffline && this.isSameUser) {
                    try {
                        await HeadsetProvider.update(
                            this.headset.user.company.id,
                            this.headset.id,
                            {
                                pretty_name: this.headset.name,
                            }
                        ).call()
                    } catch (e) {
                        if (e.hasStatus(422)) this.errors = e.errors
                        return
                    }
                }
                if (this.headset.isConnected) this.headset.saveState()
                if (window.IS_TABLET) this.headset.setStateProtection(false)
                this.alert(this.$tc('headsets.alerts.saved', 1), 'success')
                this.$emit('close')
            },
            handleDelete() {
                this.setGlobalDialog({
                    visible: true,
                    title: this.$t('headsets.actions.delete._name'),
                    message: this.$t('headsets.actions.delete.validation', {
                        name: this.headset.name,
                    }),
                    positiveButton: {
                        title: this.$t('generics.yes'),
                        action: () => {
                            this.unSyncAnHeadset(this.headset)
                        },
                    },
                })
            },
        },

        watch: {
            'headset.state.settings': {
                handler(n, o) {
                    if (!o) return
                    if (!window.IS_TABLET) {
                        this.handleSave()
                    }
                },
                deep: true,
            },
            'headset.serialNumber': {
                handler() {
                    this.temporary_name = this.headset.name
                    this.handleEmptyState()
                },
            },
        },
    }
</script>
<style lang="scss">
    .headset-settings {
        .ra-stack {
            position: relative;

            .headset-settings-close {
                position: absolute;
                top: 1.23em;
                left: 2em;
                transform: translate(-10px, -15px);
                color: var(--primary);
            }
        }
        .help-text {
            display: block;
            opacity: 0.6;
            margin-top: 0.5em !important;
        }
    }
</style>

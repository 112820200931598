<script>
import { mixins } from "@realityteam/reality-academy-ui-kit";

export default {
  name: "StopStreamDialog",
  mixins: [mixins.RaDialogMixin],
};
</script>

<template>
  <ra-card class="text-center">
    <ra-stack>
      <h3 class="text-uppercase">
        {{ $t("headsets.pilotage.casting.stop_confirm.title") }}
      </h3>
      <p>{{ $t("headsets.pilotage.casting.stop_confirm_message") }}</p>
      <!--  <ra-stack direction="horizontal" alignement="space-between">
        x
      </ra-stack> -->
      <ra-box padding="5,5">
        <ra-stack
          class="container-test"
          direction="horizontal"
          distribution="fill-equaly"
          gap="5"
        >
          <ra-button bg="primary-lighten-1" @click="close()">
            {{ $t("generics.no") }}
          </ra-button>
          <ra-button
            bg="primary"
            @click="
              $emit('stop');
              close();
            "
          >
            {{ $t("generics.yes") }}
          </ra-button>
        </ra-stack>
      </ra-box>
    </ra-stack>
  </ra-card>
</template>

<style lang="scss"></style>

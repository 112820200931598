<script>
    import { mapState } from 'pinia'
    import { useLanguageStore } from '@store/lang.js'

    export default {
        name: 'PilotageSettings',
        props: {
            settings: {
                type: Object,
                default: () => {},
            },
            headset: {
                type: Object,
            },
        },
        computed: {
            ...mapState(useLanguageStore, {
                storeLocales: (state) => state.locales,
            }),
            locales() {
                if (
                    Array.isArray(this?.value?.languages) &&
                    this.value.languages.length
                ) {
                    return this.value.languages.map((l) => ({ code: l }))
                } else {
                    return this.storeLocales
                }
            },
            value: {
                get() {
                    return this.settings
                },
                set(value) {
                    this.$emit('update:settings', value)
                },
            },
        },
    }
</script>

<template>
    <web>
        <ra-stack
            alignement="strech"
            direction="horizontal"
            distribution="fill-equaly"
            gap="6"
            mobile-swap
        >
            <ra-card>
                <template #title>
                    {{ $t('headsets.settings.headset_user_settings.label') }}
                </template>
                <ra-stack padding="4,">
                    <ra-typo
                        semi
                        variant="x-small"
                        with-track
                    >
                        {{
                            $t(
                                'headsets.settings.headset_user_settings.interacion.label'
                            )
                        }}
                    </ra-typo>
                    <ra-tab-select
                        v-model="value.interactionMode"
                        :options="[
                            {
                                label: $t(
                                    'headsets.settings.headset_user_settings.interacion.values.pointer'
                                ),
                                value: 'pointer',
                            },
                            {
                                label: $t(
                                    'headsets.settings.headset_user_settings.interacion.values.controller'
                                ),
                                value: 'controller',
                            },
                        ]"
                    />
                    <template
                        v-if="
                            headset?.state?.model.toLowerCase().indexOf('g3') ==
                            -1
                        "
                    >
                        <ra-typo
                            semi
                            variant="x-small"
                            with-track
                        >
                            {{
                                $t(
                                    'headsets.settings.headset_user_settings.controller.label'
                                )
                            }}
                        </ra-typo>
                        <ra-tab-select
                            v-model="value.controllerPreferences"
                            :options="[
                                {
                                    label: $t(
                                        'headsets.settings.headset_user_settings.controller.values.left'
                                    ),
                                    value: 'left',
                                },
                                {
                                    label: $t(
                                        'headsets.settings.headset_user_settings.controller.values.right'
                                    ),
                                    value: 'right',
                                },
                            ]"
                        />
                    </template>
                    <ra-typo
                        semi
                        variant="x-small"
                        with-track
                    >
                        {{
                            $t(
                                'headsets.settings.headset_user_settings.languages.label'
                            )
                        }}
                    </ra-typo>
                    <ra-stack
                        direction="horizontal"
                        wrap
                    >
                        <ra-btn
                            v-for="locale of locales"
                            :bg="
                                value.language == locale.code
                                    ? 'primary'
                                    : 'primary-lighten-1'
                            "
                            semi
                            size="small"
                            uppercase
                            @click="value.language = locale.code"
                        >
                            {{ locale.code }}
                        </ra-btn>
                    </ra-stack>
                </ra-stack>
            </ra-card>
            <ra-card>
                <template #title>
                    {{
                        $t('headsets.settings.headset_formation_settings.title')
                    }}
                </template>
                <ra-stack
                    gap="2"
                    padding="4,"
                >
                    <ra-typo
                        semi
                        variant="x-small"
                        with-track
                    >
                        {{
                            $t(
                                'headsets.settings.headset_formation_settings.start_mode.label'
                            )
                        }}
                    </ra-typo>
                    <ra-tab-select
                        v-model="value.launchTraining"
                        :options="[
                            {
                                label: $t(
                                    'headsets.settings.headset_formation_settings.start_mode.values.use_mode'
                                ),
                                value: 'use',
                            },
                            {
                                label: $t(
                                    'headsets.settings.headset_formation_settings.start_mode.values.learner_mode'
                                ),
                                value: 'learner',
                            },
                        ]"
                    />
                    <ra-input name="learnerCanChooseModule">
                        <v-switch
                            v-model="value.learnerCanChooseModule"
                            :label="
                                $t(
                                    'headsets.settings.headset_formation_settings.choice_module.label'
                                )
                            "
                            color="primary-gradient"
                            density="compact"
                            inset
                        />
                    </ra-input>
                    <ra-typo
                        semi
                        variant="x-small"
                        with-track
                    >
                        Quiz</ra-typo
                    >
                    <ra-input name="quizVR">
                        <v-switch
                            v-model="value.quiz.vr"
                            :label="
                                $t(
                                    'headsets.settings.headset_formation_settings.quiz.vr_quiz'
                                )
                            "
                            color="primary-gradient"
                            density="compact"
                            hide-details
                            inset
                        />
                    </ra-input>
                    <ra-input name="quizEL">
                        <v-switch
                            v-model="value.quiz.el"
                            :label="
                                $t(
                                    'headsets.settings.headset_formation_settings.quiz.el_quiz'
                                )
                            "
                            color="primary-gradient"
                            density="compact"
                            hide-details
                            inset
                        />
                    </ra-input>
                </ra-stack>
            </ra-card>
        </ra-stack>
    </web>
    <tablet>
        <ra-stack
            alignement="strech"
            direction="horizontal"
            distribution="fill-equaly"
            gap="4"
            mobile-swap
        >
            <ra-stack
                bg="surface-darken-1"
                padding="4"
                rounded
            >
                <ra-typo
                    bold
                    variant="subtitle"
                >
                    {{ $t('headsets.settings.headset_user_settings.label') }}
                </ra-typo>
                <ra-typo
                    semi
                    variant="x-small"
                    with-track
                >
                    {{
                        $t(
                            'headsets.settings.headset_user_settings.interacion.label'
                        )
                    }}
                </ra-typo>
                <ra-tab-select
                    v-model="value.interactionMode"
                    :options="[
                        {
                            label: $t(
                                'headsets.settings.headset_user_settings.interacion.values.pointer'
                            ),
                            value: 'pointer',
                            icon: 'fa-eye',
                        },
                        {
                            label: $t(
                                'headsets.settings.headset_user_settings.interacion.values.controller'
                            ),
                            value: 'controller',
                            icon: 'fa-controller',
                        },
                    ]"
                    class="select-tab-settings"
                    density="small"
                />
                <template
                    v-if="
                        headset?.state?.model.toLowerCase().indexOf('g3') == -1
                    "
                >
                    <ra-typo
                        semi
                        variant="x-small"
                        with-track
                    >
                        {{
                            $t(
                                'headsets.settings.headset_user_settings.controller.label'
                            )
                        }}
                    </ra-typo>
                    <ra-tab-select
                        v-model="value.controllerPreferences"
                        :options="[
                            {
                                label: $t(
                                    'headsets.settings.headset_user_settings.controller.values.left'
                                ),
                                value: 'left',
                            },
                            {
                                label: $t(
                                    'headsets.settings.headset_user_settings.controller.values.right'
                                ),
                                value: 'right',
                            },
                        ]"
                        density="small"
                    />
                </template>
                <ra-typo
                    semi
                    variant="x-small"
                    with-track
                >
                    {{
                        $t(
                            'headsets.settings.headset_user_settings.languages.label'
                        )
                    }}
                </ra-typo>
                <ra-stack
                    direction="horizontal"
                    wrap
                >
                    <ra-btn
                        v-for="locale of locales"
                        :bg="
                            value.language == locale.code
                                ? 'primary'
                                : 'primary-lighten-1'
                        "
                        semi
                        size="small"
                        uppercase
                        @click="value.language = locale.code"
                    >
                        {{ locale.code }}
                    </ra-btn>
                </ra-stack>
            </ra-stack>

            <ra-stack
                bg="surface-darken-1"
                gap="2"
                padding="4"
                rounded
            >
                <ra-typo
                    bold
                    variant="subtitle"
                >
                    {{
                        $t('headsets.settings.headset_formation_settings.title')
                    }}
                </ra-typo>
                <ra-typo
                    semi
                    variant="x-small"
                    with-track
                >
                    {{
                        $t(
                            'headsets.settings.headset_formation_settings.start_mode.label'
                        )
                    }}
                </ra-typo>
                <ra-tab-select
                    v-model="value.launchTraining"
                    :options="[
                        {
                            label: $t(
                                'headsets.settings.headset_formation_settings.start_mode.values.use_mode'
                            ),
                            value: 'use',
                            icon: 'fa-eye',
                        },
                        {
                            label: $t(
                                'headsets.settings.headset_formation_settings.start_mode.values.learner_mode'
                            ),
                            value: 'learner',
                            icon: 'fa-graduation-cap',
                        },
                    ]"
                    class="select-tab-settings"
                    density="small"
                />
                <ra-input name="learnerCanChooseModule">
                    <v-switch
                        v-model="value.learnerCanChooseModule"
                        :label="
                            $t(
                                'headsets.settings.headset_formation_settings.choice_module.label'
                            )
                        "
                        color="primary-gradient"
                        density="compact"
                        hide-details
                        inset
                    />
                </ra-input>
                <ra-typo
                    semi
                    variant="x-small"
                    with-track
                >
                    {{
                        $t(
                            'headsets.settings.headset_formation_settings.quiz.label'
                        )
                    }}
                </ra-typo>
                <ra-box>
                    <ra-input name="quizVR">
                        <v-switch
                            v-model="value.quiz.vr"
                            :label="
                                $t(
                                    'headsets.settings.headset_formation_settings.quiz.vr_quiz'
                                )
                            "
                            color="primary-gradient"
                            density="compact"
                            hide-details
                            inset
                        />
                    </ra-input>
                    <ra-input name="quizEL">
                        <v-switch
                            v-model="value.quiz.el"
                            :label="
                                $t(
                                    'headsets.settings.headset_formation_settings.quiz.el_quiz'
                                )
                            "
                            color="primary-gradient"
                            density="compact"
                            hide-details
                            inset
                        />
                    </ra-input>
                </ra-box>
            </ra-stack>
        </ra-stack>
    </tablet>
</template>
<style lang="scss">
    .select-tab-settings {
        .ra-tab-select__option_icon {
            //make a gradient text
            font-weight: 600;
            font-size: 0.9rem !important;
            position: relative;
            top: 2px;
            left: -5px;
        }
        .ra-tab-select__option--active {
            .ra-tab-select__option_icon {
                //make a gradient text

                background: var(--primary-gradient);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
</style>

<script>
    import ApiClient from '@api'

    const COOLDOWN_TYPE = 300
    export default {
        name: 'SearchBar',
        data() {
            return {
                searchText: '',
                cooldown: null,
                active: false,
                loading: false,
                results: [],
            }
        },
        methods: {
            goToUrl(item) {
                window.location.href = item.url
                this.active = false
            },
            async searchResults() {
                this.loading = true
                if (this.searchText == null) this.active = false
                let api = new ApiClient()
                try {
                    let response = await api
                        .get('/search', { query: this.searchText })
                        .call()
                    this.results = response.data
                } catch (e) {
                    console.error(e)
                } finally {
                    this.active = true
                    this.loading = false
                }
            },
        },
        watch: {
            searchText(newValue) {
                if (this.cooldown != null) clearTimeout(this.cooldown)
                this.cooldown = setTimeout(() => {
                    this.searchResults(newValue)
                }, COOLDOWN_TYPE)
            },
        },
    }
</script>

<template>
    <v-menu v-model="active">
        <template v-slot:activator="{ props }">
            <ra-input name="search">
                <v-text-field
                    v-model="searchText"
                    :loading="loading"
                    density="compact"
                    hide-details
                    placeholder="Rechercher ..."
                    v-bind="props"
                    variant="outlined"
                    @click.stop="() => true"
                ></v-text-field>
            </ra-input>
        </template>
        <v-list dense>
            <v-list-item
                v-for="(item, index) in results"
                :key="index"
                class="search-result-item"
                @click="goToUrl(item)"
            >
                <ra-stack
                    alignement="center"
                    direction="horizontal"
                    gap="4"
                >
                    <div class="search-image">
                        <img
                            v-if="item.image"
                            :src="item.image"
                        />
                    </div>
                    <div>
                        <ra-typo variant="small">
                            {{ item.text }}
                        </ra-typo>
                        <ra-typo
                            semi
                            variant="small"
                        >
                            {{ item.type }}
                        </ra-typo>
                    </div>
                </ra-stack>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<style lang="scss" scoped>
    .search-result-item {
        .search-image {
            height: 50px;
            width: 50px;
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
            }
        }
        line-height: 1.2em !important;
    }
</style>

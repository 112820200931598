
<script>
export default {
  name: "FormationEllipsizer",
  props: {
    items: {
      type: Array,
    },
    count: {
      type: Number,
      default: 3,
    },
  },

  computed: {
    countText() {
      return this.items.length - this.count > 0
        ? " +" + (this.items.length - this.count)
        : "";
    },
    ellipsizedText() {
      return this.ellipsis();
    },
  },

  methods: {
    ellipsis: function () {
      var str = "";
      for (var i = 0; i < this.items.length; i++) {
        if (i >= this.count) {
          str = str.substring(0, str.length - 2) + ", ";
          break;
        }
        str += this.items[i]["name"] + ", ";
      }
      str = str.substring(0, str.length - 2);
      return str;
    },
  },
};
</script>

<template>
  <v-menu offset-y open-on-hover rounded="lg">
    <template v-slot:activator="{ attrs }">
      <div>
        {{ ellipsizedText }}
        <span class="bold" v-bind="attrs">
          {{ countText }}
        </span>
      </div>
    </template>

    <div
        class="blue-text pa-6 text-ellipsis"
    >
      <div class="mb-1" style="font-size: 1.1em">
        <ul>
          <li v-for="(f, i) in items" :key="'formation-' + i">
            {{ f.name }}
          </li>
        </ul>
      </div>
    </div>
  </v-menu>
</template>

<style lang="scss" scoped>
.text-ellipsis{
  background-color: white;
  line-height: 20px;
  font-size: 0.9em
}
</style>
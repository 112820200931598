import ApiClient from '@api'

export class UploadProvider {
    static route = '/files'
    static api = ApiClient.getInstance()

    static upload = async (formData, onProgress = () => {}) => {
        return await this.api
            .post(
                this.route,
                formData,
                {},
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress(progressEvent) {
                        onProgress(progressEvent)
                    },
                }
            )
            .call()
    }
}

import { reactive } from 'vue'
import { HeadsetManager } from './HeadsetManager'
import { WSHeartBeat } from '@libs/MDM/core/WSHeartBeat.js'
export const WSType = {
    OFFER: 'offer',
    ANSWER: 'answerOffer',
    IDENTIFICATION: 'identification',
    HEADSET_UPDATE: 'headsetUpdate',
    STATE: 'state',
    ICE: 'iceCandidate',
    FORMATION_STATE: 'formationState',
}

export const WSEvent = {
    OPEN: 'open',
    MESSAGE: 'message',
    CLOSE: 'close',
    ERROR: 'error',
    HEADSETS: 'headsets',
}

export class WebSocketClient extends WebSocket {
    static instances = new Map()

    constructor(url, type = 'pwa', ...props) {
        /**
         * The query string parameters to send to the websocket server
         * @type {{accessToken: string, type: string, env: any}}
         */
        const params = {
            accessToken: localStorage.getItem('access_token'),
            type,
            env: import.meta.env.VITE_APP_ENV,
        }
        const queryString = new URLSearchParams(params).toString()

        /**
         * start the websocket connection
         */
        super(url + '?' + queryString, ...props)

        /**
         * The headset manager to sync headsets connected to the websocket, see handleMessage method
         * @type {null|HeadsetManager}
         */
        this.headsetManager = null

        this.init()

        /**
         * The host of the websocket, set by the getInstance method
         * @type {null}
         */
        this.host = null

        /**
         * The type of the websocket, usefull in Headset class
         * @type {boolean}
         */
        this.isLAN = false

        this.closedByUser = false

        return reactive(this)
    }

    /**
     * Get the websocket instance for a specific host, only one instance per host
     * @returns {WebSocketClient}
     */
    static getInstance(url) {
        let { host } = new URL(url)
        if (!WebSocketClient.instances.has(host)) {
            let ws = new WebSocketClient(url)
            ws.host = host
            WebSocketClient.instances.set(host, ws)
        }
        return WebSocketClient.instances.get(host)
    }

    /**
     * Set the headsets manager to sync headsets connected to the websocket
     * @param {HeadsetManager} headsetManager
     * @returns {WebSocketClient}
     */
    setHeadsetManager(headsetManager) {
        this.headsetManager = headsetManager
        return this
    }

    /**
     * Define the websocket as a LAN websocket
     * @returns {WebSocketClient}
     */
    defineAsLAN() {
        this.isLAN = true
        return this
    }

    /**
     * Add an event listener to the websocket
     * @param params
     */
    on(...params) {
        this.addEventListener(...params)
    }

    /**
     * Send an event to the listeners
     * @param name
     * @param data
     */
    emit(name, data) {
        this.dispatchEvent(new CustomEvent(name, { detail: data }))
    }

    /**
     * init the websocket listeners
     */
    init() {
        this.on(WSEvent.OPEN, this.handleConnection.bind(this))
        this.on(WSEvent.MESSAGE, this.handleMessage.bind(this))
        this.on(WSEvent.CLOSE, this.handleClose.bind(this))
    }

    handleConnection() {}

    /**
     * Handle the closing of the websocket, if not closed by the client but by the remote host, show an alert
     */
    handleClose() {
        if (!this.closedByUser) {
            window.$alert('Headset detection not available', { color: 'error' })
        }
    }

    /**
     * Close the websocket connection, close by the client side
     */
    close() {
        this.closedByUser = true
        super.close()
    }

    /**
     * Handle the message received by the websocket
     * Here is the support of RAWebsocket Protocol messages
     * @param event
     * @returns {Promise<void>}
     */
    async handleMessage(event) {
        if (WSHeartBeat.isPingMessage(event.data)) {
            return WSHeartBeat.sendPong(this)
        }
        const json = JSON.parse(event.data)
        let headset
        switch (json.type) {
            case WSType.HEADSET_UPDATE:
                //Sync the linked headset manager on connection or disconnection of a headset
                if (this.headsetManager) {
                    this.headsetManager.sync(
                        json.headsets.map(({ serialNumber, state }) => ({
                            serialNumber,
                            config: {
                                ws: this,
                                state,
                            },
                        }))
                    )
                    this.emit(WSEvent.HEADSETS, this.headsetManager.all)
                } else {
                    this.emit(WSEvent.HEADSETS, json.headsets)
                }
                break
            case WSType.OFFER:
                headset = this.headsetManager.get(json.serialNumber)
                headset.receivingRTCConnection.signal(json.offer)
                break
            case WSType.ANSWER:
                headset = this.headsetManager.get(json.serialNumber)
                await headset.emitingRTCConnection.signal(json.answer)
                break
            case WSType.STATE:
                if (!this.headsetManager) break
                headset = this.headsetManager.get(json.serialNumber)
                headset.setState({
                    ...json.state,
                    connected: true,
                })
                break
            case WSType.ICE:
                headset = this.headsetManager.get(json.serialNumber)
                if (json.candidate.initiator) {
                    headset.receivingRTCConnection.signal(
                        json.candidate.candidate
                    )
                } else {
                    headset.emitingRTCConnection.signal(
                        json.candidate.candidate
                    )
                }
                break
            case WSType.FORMATION_STATE:
                headset = this.headsetManager.get(json.serialNumber)
                headset.setFormationState(json.state)
                break
            default:
                break
        }
    }
}

import { defineStore } from 'pinia'
import { UserProvider } from '@providers'
import { APIError } from '@core/api/APIError'
import { APP_MODE, useAppStore } from './app'
import { UserStructure } from '@libs/Structures/UserStructure.js'
import Stack from '@utils/Stack.js'

export const AUTH_STATE = {
    UNKOWN: undefined,
    AUTHENTIFICATED: true,
    UNAUTHENTIFICATED: false,
}

export const AUTH_TYPE = {
    LEARNER: 'learner',
    USER: 'user',
}

export const LoginResponseType = {
    PRESTA_MIDDLEWARE: 'presta_middleware',
    LOGGED: 'logged',
    NOT_LOGGED: 'not_logged',
}

export const useUserStore = (onReady = () => {}) => {
    const appStore = useAppStore()
    const store = defineStore('user', {
        state() {
            let lastLoggedUser = new Stack(
                JSON.parse(localStorage.getItem('lastLoggedUser')) || [],
                'lastLoggedUser'
            )
            let data = {}
            data.access_token = localStorage.getItem('access_token') || null
            data._user = localStorage.getItem('user') || null
            data.isReady = !Boolean(localStorage.getItem('access_token'))
            data.lastLoggedUser = lastLoggedUser
            data.refreshTokenUsed = []
            return data
        },

        getters: {
            isAuth(state) {
                return state.access_token
                    ? AUTH_STATE.AUTHENTIFICATED
                    : AUTH_STATE.UNAUTHENTIFICATED
            },
            company(state) {
                return state.user?.company
            },
            isGod(state) {
                return state?.user?.isGod
            },

            isApprenant(state) {
                return state?.user?.isApprenant
            },
            isUser(state) {
                return state?.user?.isUser
            },
        },

        actions: {
            companyLogo(fallback) {
                if (this.company?.logo?.url) return this.company.logo.url
                return fallback
            },
            hasRole(...args) {
                return this.user?.hasRole(...args)
            },
            setToken(access_token) {
                this.access_token = access_token
                localStorage.setItem('access_token', access_token)
            },
            setRefreshToken(refresh_token = null) {
                if (!refresh_token)
                    return localStorage.removeItem('refresh_token')
                localStorage.setItem('refresh_token', refresh_token)
            },
            getRefreshToken() {
                let token = localStorage.getItem('refresh_token')
                let isUsedToken = this.refreshTokenUsed.find(
                    (t) => t[0] == token && new Date().getTime() - t[1] < 3000
                )
                if (isUsedToken) return null
                return token
            },
            setOldUser(old_user_id) {
                this.lastLoggedUser.push(old_user_id)
            },

            async login(login, password, customer_email, vue) {
                this.lastLoggedUser.clear()
                try {
                    let request
                    if (!customer_email) {
                        request = UserProvider.login({
                            email: login,
                            password,
                        })
                    } else if (window.IS_TABLET) {
                        request = UserProvider.loginAs({
                            email: login,
                            user_email: customer_email,
                            password,
                        })
                    }
                    const { data } = await request.disableAutoDisconect().call()
                    return await this.handleLoginResponse(data)
                } catch (error) {
                    appStore.setMode(APP_MODE.OFFLINE)
                    if (error instanceof APIError) {
                        vue.errors = {
                            ...error.errors,
                        }
                        vue?.alert(error.apiMessage, 'error', -1)
                    }
                    return LoginResponseType.NOT_LOGGED
                }
            },

            async handleLoginResponse(payload) {
                try {
                    if (payload.is_presta)
                        return LoginResponseType.PRESTA_MIDDLEWARE
                    this.setToken(payload.access_token)
                    this.setRefreshToken(payload.refresh_token)
                    await this.fetch()
                    appStore.setMode(APP_MODE.ONLINE)
                    return LoginResponseType.LOGGED
                } catch (e) {
                    throw e
                    return LoginResponseType.NOT_LOGGED
                }
            },

            async getNewToken() {
                let refreshToken = this.getRefreshToken()
                if (!refreshToken) return false
                this.refreshTokenUsed.push([refreshToken, new Date().getTime()])
                try {
                    const { data } = await UserProvider.refreshToken({
                        refresh_token: refreshToken,
                    })
                        .doNotRetry()
                        .callImmediately()
                    this.setToken(data.access_token)
                    this.setRefreshToken(data.refresh_token)
                    return true
                } catch (error) {
                    this.logout(() =>
                        appStore.goToLoginPage({
                            error: 'Disconnected from server',
                        })
                    )
                    return false
                }
            },

            async logout(redirect = false) {
                if (window.$MDM) window.$MDM.stop()
                this.access_token = null
                this.user = null
                this.lastLoggedUser.clear()
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('user')
                appStore.setMode(APP_MODE.OFFLINE)
                if (window.$MDM) window.$MDM.stop()
                if (redirect) redirect()
            },

            async fetch(redirect = false) {
                if (this.isAuth != AUTH_STATE.AUTHENTIFICATED) return false
                try {
                    const request = UserProvider.me()
                    if (!redirect) request.disableAutoDisconect()
                    const { data } = await request.call()
                    this.user = new UserStructure(data)
                    this.onReady(this)
                    this.isReady = true
                } catch (error) {
                    this.logout()
                    throw error
                }
            },

            async loginWithToken(
                accessToken,
                old_user_id = null,
                callback = null
            ) {
                if (window.$MDM) window.$MDM.stop()
                if (old_user_id) this.setOldUser(old_user_id)
                this.setToken(accessToken)
                await this.fetch(false)
                appStore.setMode(APP_MODE.ONLINE)
                if (callback) callback()
            },
        },
    })
    let storeInstance = store()

    if (typeof onReady == 'function') {
        storeInstance.onReady = onReady
        if (storeInstance.isReady) storeInstance.onReady(storeInstance)
    }

    return storeInstance
}

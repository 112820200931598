import { authentificatedRoutesMeta as meta } from '@core/router/routes/Authentificated/meta.js'
import { ResourcesRoutesName } from '@core/router/routes/Authentificated/Resources.names.js'

export default [
    {
        path: '/resources',
        component: () => import('@pages/Ressources/RessourcesPage.vue'),
        children: [
            {
                path: '',
                name: ResourcesRoutesName.index,
                meta: {
                    ...meta,
                    script: 'ressource',
                },
            },
            {
                path: 'videos',
                children: [
                    {
                        path: '',
                        name: ResourcesRoutesName.videos.index,
                        meta,
                        component: () =>
                            import('@pages/Ressources/VideosPage.vue'),
                    },
                ],
            },
            {
                path: 'faqs',
                children: [
                    {
                        path: '',
                        name: ResourcesRoutesName.faqs.index,
                        meta,
                        component: () =>
                            import('@pages/Ressources/FAQPage.vue'),
                    },
                    {
                        path: ':faq_id',
                        name: ResourcesRoutesName.faqs.show,
                        meta,
                        component: () =>
                            import('@pages/Ressources/FAQShowPage.vue'),
                    },
                ],
            },
            {
                path: 'educational-guides',
                name: ResourcesRoutesName.educational_guide.index,
                meta,
                component: () =>
                    import('@pages/Ressources/EducationalGuidesPage.vue'),
            },
            {
                path: 'synthesis-sheets',
                name: ResourcesRoutesName.synthesis_sheet.index,
                meta,
                component: () =>
                    import('@pages/Ressources/SynthesisSheetsPage.vue'),
            },
        ],
    },
    //edit and create routes are in god routes
]

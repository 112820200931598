import bootstrap from '@config/bootstrap'

const searchParams = new URLSearchParams(window.location.search)
window.IS_TABLET =
    import.meta.env?.VITE_APPLICATION_TABLET_MODE == 'true' ||
    searchParams.get('tablet') == 'true'
window.IS_ONLINE = true

async function testConnectivity() {
    try {
        let abortController = new AbortController()
        setTimeout(() => abortController.abort(), 2000)
        await fetch(
            `${import.meta.env.VITE_API_URL}/languages?connectivityTest=true`,
            {
                method: 'OPTIONS',
                signal: abortController.signal,
            }
        )
        return true
    } catch (e) {
        return false
    }
}

let app
window.setIsTablet = async (isTablet) => {
    window.IS_TABLET = isTablet
    app.unmount()
    app = await bootstrap()
}
;(async () => {
    window.IS_ONLINE = await testConnectivity()
    app = await bootstrap()
})()

<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import HeadsetsList from './HeadsetsList.vue'
    import BatteryLevel from './BatteryLevel.vue'
    import ConnectedStatus from './ConnectedStatus.vue'
    import { mapState } from 'pinia'
    import { useAppStore } from '@store/app.js'

    export default {
        name: 'SearchHeadsetDialog',
        mixins: [mixins.RaDialogMixin],
        props: ['headsets'],
        components: { HeadsetsList, BatteryLevel, ConnectedStatus },
        data() {
            return {
                toConnect: [],
                filtersValues: [true, false],
            }
        },
        computed: {
            ...mapState(useAppStore, ['isOffline']),
            filters() {
                return [
                    {
                        icon: 'mdi-lan',
                        title:
                            this.$t(
                                'headsets.pilotage.steps.step0.add_headset.provided_by_wifi'
                            ) + ` (${this.wifiCount})`,
                        visible: true,
                    },
                    {
                        icon: 'mdi-signal-variant',
                        title:
                            this.$t(
                                'headsets.pilotage.steps.step0.add_headset.provided_by_internet'
                            ) + ` (${this.internetCount})`,
                        visible: !this.isOffline,
                    },
                ].filter((filter) => filter.visible)
            },
            headsetFiltered() {
                return this.headsets
                    .filter((headset) => {
                        if (this.filtersValues[0] && headset._isLAN) {
                            return true
                        }
                        if (
                            this.filtersValues[1] &&
                            (!headset._isLAN || !headset.isConnected)
                        ) {
                            return true
                        }
                        return false
                    })
                    .sort(
                        (a, b) =>
                            b.isConnected - a.isConnected ||
                            a.name.localeCompare(b.name)
                    )
            },
            wifiCount() {
                return this.headsets.filter((headset) => headset._isLAN).length
            },
            internetCount() {
                return this.headsets.length - this.wifiCount
            },
        },

        methods: {
            syncHeadsets() {
                this.$emit('sync', this.toConnect)
                this.close()
                this.toConnect = []
            },
            toggleFilter(index) {
                this.filtersValues = this.filtersValues.map((value, i) =>
                    i === index ? true : false
                )
            },
        },
    }
</script>

<template>
    <ra-card
        class="text-center search-headset-dialog"
        padding="4"
    >
        <ra-stack gap="8">
            <ra-box center>
                <ra-typo
                    bold
                    uppercase
                    variant="title"
                >
                    {{
                        $t(
                            'headsets.pilotage.steps.step0.add_headset.title_card'
                        ).toUpperCase()
                    }}
                </ra-typo>
                <ra-spacer height="2px" />
                <ra-typo
                    italic
                    variant="subtitle"
                >
                    {{
                        $t(
                            'headsets.pilotage.steps.step0.add_headset.description'
                        )
                    }}
                </ra-typo>
            </ra-box>
            <ra-stack direction="horizontal">
                <v-btn
                    v-for="(filter, i) in filters"
                    :color="filtersValues[i] ? 'primary' : 'primary-lighten-1'"
                    @click="toggleFilter(i)"
                    :key="i"
                    variant="flat"
                    size="small"
                >
                    <ra-stack
                        direction="horizontal"
                        gap="2"
                    >
                        <ra-icon color="white">
                            {{ filter.icon }}
                        </ra-icon>
                        {{ filter.title }}
                    </ra-stack>
                </v-btn>
            </ra-stack>
            <ra-box>
                <hr
                    v-if="!headsetFiltered.length"
                    class="mb-5"
                />
                <HeadsetsList
                    v-model="toConnect"
                    :columns="[
                        {
                            name: 'image',
                            width: '60px',
                        },
                        {
                            name: 'name',
                            width: '40%',
                        },
                        {
                            name: 'connectedStatus',
                            width: '100px',
                        },
                        {
                            name: 'battery',
                            width: '80px',
                        },
                        {
                            name: 'select',
                            width: '80px',

                            props: {
                                class: ['pl-5', 'pr-2'],
                            },
                        },
                    ]"
                    :headsets="headsetFiltered"
                    :no-headset="
                        $t(
                            'headsets.pilotage.steps.step0.add_headset.no_lan_headset'
                        )
                    "
                    :rows="{
                        height: '55px',
                    }"
                >
                    <template v-slot:image="{ headset }">
                        <img
                            :src="headset.image"
                            alt="headset_image"
                            class="object-fit-contain"
                            height="64"
                        />
                    </template>
                    <template v-slot:name="{ headset }">
                        <ra-box>
                            <ra-typo
                                bold
                                class="text-left"
                                maxWidth="200px"
                                no-word-wrap
                            >
                                {{ headset.name || $t('headsets._unknown') }}
                            </ra-typo>
                        </ra-box>
                    </template>
                    <template v-slot:battery="{ headset }">
                        <battery-level :value="headset.state.batteryLevel" />
                    </template>
                    <template v-slot:connectedStatus="{ headset }">
                        <connected-status
                            :isLAN="headset._isLAN"
                            :value="headset.isConnected"
                        />
                    </template>
                    <template v-slot:select="props">
                        <v-btn
                            :icon="props.isSelected ? 'mdi-check' : 'mdi-plus'"
                            color="primary"
                            density="comfortable"
                            variant="plain"
                        />
                    </template>
                </HeadsetsList>
            </ra-box>
            <ra-stack
                direction="horizontal"
                distribution="fill-equaly"
                gap="8"
            >
                <ra-btn
                    bg="primary-lighten-1"
                    semi
                    @click="close()"
                >
                    {{ $t('generics.cancel') }}
                </ra-btn>
                <ra-btn
                    bg="primary"
                    semi
                    @click="syncHeadsets"
                >
                    {{ $t('generics.add') }}
                </ra-btn>
            </ra-stack>
        </ra-stack>
    </ra-card>
</template>

<style lang="scss">
    .search-headset-dialog {
        .headset-list {
            max-height: min(40vh, 280px);
            overflow: hidden;
            overflow-y: auto;
        }
        .headset-image {
            object-fit: contain;
        }
    }
</style>

<script>
export default {
  name: "SessionsFormationRankingTable",
  data() {
    return {
      showAll: false,
    };
  },
  props: ["formation_ranking"],
  computed: {
    headers() {
      return [
        {
          title: this.$t("stats.formation_ranking.table.name"),
          value: "name",
          width: "35%",
        },
        {
          title: this.$t(
              "stats.formation_ranking.table.session_count"
          ),
          value: "sessions_count",
          align: "center",
        },
        {
          title: this.$t(
              "stats.formation_ranking.table.statisfaction_rate"
          ),
          value: "satisfaction_rate",
          align: "center",
        },
        {
          title: this.$t(
              "stats.formation_ranking.table.completion_rate"
          ),
          value: "success_rate",
        },
      ];
    },
    items() {
      let limit = this.showAll ? this.formation_ranking.length : 4;
      return this.formation_ranking.slice(0, limit);
    },
  },
};
</script>

<template>
  <v-data-table
      :headers="headers"
      :hide-default-footer="!showAll"
      :items="items"
  >
    <template v-slot:item.name="{ item }">
      <ra-stack alignement="center" direction="horizontal" padding="4,">
        <img
            :src="item.thumbnail.url"
            alt=""
            class="image"
            height="50"
            width="89"
        />
        <ra-typo semi variant="subtitle">{{ item.name }}</ra-typo>
      </ra-stack>
    </template>
    <template v-slot:item.satisfaction_rate="{ item }">
      <v-chip>
        {{ Math.round(item.satisfaction_rate) }} %
      </v-chip>
    </template>
    <template v-slot:item.success_rate="{ item }">
      <ra-stack alignement="center" direction="horizontal">
        <ra-box flex="1 1 auto">
          <v-progress-linear
              :value="item.success_rate"
              class="rounded-pill"
              color="primary"
              height="12"
              rounded
          />
        </ra-box>
        <ra-typo> {{ Math.round(item.success_rate) }}%</ra-typo>
      </ra-stack>
    </template>
  </v-data-table>
  <p class="text-center primary--text" @click="showAll = !showAll">
      <span class="underline cursor-pointer">
        {{
          showAll
              ? $t("stats.formation_ranking.hide_all")
              : $t("stats.formation_ranking.display_all")
        }}
      </span>
      <v-icon>
        {{ showAll ? "mdi-chevron-up" : "mdi-chevron-down" }}
      </v-icon>
  </p>
</template>

<style lang="scss" scoped>
.image{
  object-fit: cover;
}
</style>


<template>
    <ra-box
        class="headset-selection"
        padding="10"
    >
        <ra-stack
            bg="white"
            gap="8"
            padding="8"
            rounded
        >
            <ra-box center>
                <ra-typo
                    bold
                    uppercase
                    variant="title"
                >
                    {{ $t('headsets.pilotage.steps.step0.title_card') }}
                </ra-typo>
                <ra-spacer height="2px" />
                <ra-typo
                    italic
                    variant="subtitle"
                >
                    {{ $t('headsets.pilotage.steps.step0.description') }}
                </ra-typo>
            </ra-box>
            <ra-box
                class="headset-selection-list"
                scroll-y
            >
                <HeadsetsList
                    :columns="[
                        {
                            name: 'image',
                        },
                        {
                            name: 'connectedStatus',
                            width: '140px',
                        },
                        {
                            name: 'battery',
                            width: '140px',
                        },
                        {
                            name: 'select',
                            width: '100px',
                        },
                    ]"
                    :disabled="true"
                    :headsets="headsets"
                    :no-headset="null"
                    :rows="{
                        bg: 'surface-darken-1',
                        height: '50px',
                    }"
                    withBorder
                >
                    <template v-slot:image="{ headset }">
                        <ra-stack
                            alignement="center"
                            direction="horizontal"
                            gap="6"
                        >
                            <img
                                :src="headset.image"
                                alt=""
                                class="object-fit-contain"
                                height="50"
                            />
                            <ra-typo
                                semi
                                variant="subtitle"
                            >
                                {{ headset.name || $t('headsets._no_headset') }}
                            </ra-typo>
                            <ra-stack direction="horizontal">
                                <v-chip
                                    size="x-small"
                                    v-for="chip in headset.statesChips"
                                    >{{ $t(chip) }}</v-chip
                                >
                            </ra-stack>
                        </ra-stack>
                    </template>
                    <template v-slot:connectedStatus="{ headset }">
                        <connected-status
                            :isLAN="headset._isLAN"
                            :value="headset.isConnected"
                        />
                    </template>
                    <template v-slot:battery="{ headset }">
                        <battery-level :value="headset.state.batteryLevel" />
                    </template>
                    <template v-slot:select="{ headset }">
                        <v-btn
                            color="primary"
                            icon="mdi-close"
                            size="small"
                            variant="plain"
                            @click="$emit('unDrive', headset)"
                        />
                    </template>
                </HeadsetsList>
                <HeadsetsList
                    :columns="[{ name: 'empty' }]"
                    :headsets="Array(Math.max(6 - headsets.length, 0)).fill({})"
                    :no-headset="null"
                    :rows="{
                        bg: 'surface-darken-1',
                        height: '50px',
                    }"
                >
                    <template v-slot:empty>
                        <v-icon> mdi-plus</v-icon>
                    </template>
                </HeadsetsList>
            </ra-box>

            <ra-stack
                direction="horizontal"
                distribution="side-spacing"
                gap="8"
                mobile-swap
                wrap
            >
                <ra-btn
                    :disabled="!headsets.length"
                    bg="primary-gradient"
                    width="230px"
                    @click="startSession()"
                >
                    <ra-stack gap="0">
                        <ra-typo variant="x-small">
                            {{
                                $t(
                                    'headsets.pilotage.steps.step0.start_session.content'
                                )
                            }}
                        </ra-typo>
                        <ra-typo
                            semi
                            variant="subtitle"
                        >
                            {{
                                $t(
                                    'headsets.pilotage.steps.step0.start_session.content_huge'
                                )
                            }}
                        </ra-typo>
                    </ra-stack>
                </ra-btn>
                <ra-btn
                    :disabled="!headsets.length"
                    bg="primary-gradient"
                    width="230px"
                    @click="$router.push('/my-headsets/drive')"
                >
                    <ra-stack gap="0">
                        <ra-typo variant="x-small">
                            {{
                                $t(
                                    'headsets.pilotage.steps.step0.start_streaming.content'
                                )
                            }}
                        </ra-typo>
                        <ra-typo
                            semi
                            variant="subtitle"
                        >
                            {{
                                $t(
                                    'headsets.pilotage.steps.step0.start_streaming.content_huge'
                                )
                            }}
                        </ra-typo>
                    </ra-stack>
                </ra-btn>
                <v-btn
                    v-if="headsets.length > 1"
                    class="grouped-settings"
                    density="comfortable"
                    icon="mdi-cog"
                    variant="plain"
                    @click="$emit('openSettings')"
                />
            </ra-stack>
        </ra-stack>
    </ra-box>
</template>

<script>
    import { mixins } from '@realityteam/reality-academy-ui-kit'
    import ConnectedStatus from './ConnectedStatus.vue'
    import BatteryLevel from './BatteryLevel.vue'

    import SearchHeadsetDialog from './SearchHeadsetDialog.vue'
    import HeadsetsList from './HeadsetsList.vue'
    import { mapActions } from 'pinia'
    import { useGlobalDialog } from '@store/globalDialog.js'

    export default {
        components: {
            ConnectedStatus,
            BatteryLevel,
            SearchHeadsetDialog,
            HeadsetsList,
        },
        mixins: [mixins.RaDialogsMixin],
        emits: ['unDrive'],
        props: {
            headsets: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            ...mapActions(useGlobalDialog, ['setGlobalDialog']),
            startSession(confirm = false) {
                let hasInFormationHeadset = this.headsets.some(
                    (headset) => headset.isInFormation
                )
                if (hasInFormationHeadset) {
                    if (!confirm)
                        this.setGlobalDialog({
                            title: this.$t(
                                'headsets.pilotage.steps.step0.start_session.warning_dialog.title'
                            ),
                            message: this.$t(
                                'headsets.pilotage.steps.step0.start_session.warning_dialog.message'
                            ),
                            positiveButton: {
                                title: this.$t(
                                    'headsets.pilotage.steps.step0.start_session.warning_dialog.positive_btn'
                                ),
                                action: () => {
                                    this.startSession(true)
                                },
                            },
                            negativeButton: {
                                action: () => {},
                            },
                        })
                    else {
                        this.$router.push('/my-headsets/drive')
                    }
                    return
                }
                this.$router.push('/my-headsets/start-session')
            },
        },
    }
</script>
<style lang="scss">
    .headset-selection {
        --row-height: 65px;
        .headset-selection-list {
            max-height: calc(var(--row-height) * 5);
        }
        > .ra-stack {
            position: relative;
        }
        &-list {
            .ra-list-item {
                margin-bottom: 4px;
                border: none !important;
            }
        }
    }
</style>

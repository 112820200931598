import ApiClient from '..'

export class UserProvider {
    static api = ApiClient.getInstance()
    static route = `/user`

    static me() {
        return this.api.get(this.route)
    }

    static loginAs({ email, password, user_email }) {
        return this.api.post('/loginAs', { email, password, user_email, source: window.IS_TABLET ? "tablet" : "pwa", remember: true })
    }

    static login({ email, password }) {
        return this.api.post('/login', { email, password, source: window.IS_TABLET ? "tablet" : "pwa", remember: true })
    }

    static refreshToken({ refresh_token }) {
        return this.api.post('/tokens', { refresh_token })
    }

    static update(payload) {
        return this.api.put(this.route, payload)
    }

    static myHome(query) {
        return this.api.get(`/my-home`, query)
    }

    static loginAsUser(payload, typeUser = true) {
        return this.api.post(`/get-user-login`, {
            ...payload,
            typeUser: typeUser,
        })
    }

    static logoutAs(payload) {
        return this.api.post(`/logout-as`, payload)
    }

    static loginAccessLink(uuidLink) {
        return this.api.get(`/access-link/${uuidLink}`)
    }

    static forgotPassword(payload) {
        return this.api.post(`/forgot-password`, payload)
    }

    static stats() {
        return this.api.get(`${this.route}/stats`)
    }

    static contact(payload) {
        return this.api.post(`/send-mail`, payload)
    }
}

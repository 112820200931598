import { routesNames } from '@core/router/routes/index.js'
import { AuthentificatedRoutesName } from '@core/router/routes/Authentificated/index.js'
import { useAppStore } from '../../../store/app.js'
import { CompaniesRoutesName } from '@routes/God/Companies.names.js'
import { MyFormationsRoutesName } from '@core/router/routes/Authentificated/MyFormations.names.js'

/**
 * Custom script for the / router
 * @this VueRouter
 */
export default function (to, from, next, auth) {
    const appStore = useAppStore()
    if (
        appStore.isTablet &&
        (to?.query?.offline || localStorage.getItem('keepOffline'))
    )
        return next('/my-headsets') //case tablet offline
    if (!auth.isAuth) return next({ name: routesNames.login })
    else if (appStore.isTablet)
        return next('/my-headsets') //case tablet not pass through login
    else {
        let goTo = { name: AuthentificatedRoutesName.home }
        if (auth.user.isGod) {
            goTo = { name: CompaniesRoutesName.index }
        } else if (auth.user.isFormer) {
            goTo = { name: AuthentificatedRoutesName.home }
        } else if (auth.user.isApprenant) {
            goTo = { name: MyFormationsRoutesName.index }
        }
        return next(goTo)
    }
}
